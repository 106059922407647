export const texts = ({ partnerNum }) => {
  return {
    mainHeadline: '',
    cmpHeadline: 'Prosíme o souhlas',
    subHeadline: 'Předplaťte si web bez reklam*',
    cmpBody: 'S Vaším souhlasem pro zobrazování, cílení a měření reklamy můžete naše stránky používat <b>ZDARMA</b>. Díky cookies či dalším síťovým identifikátorům víme např. jak procházíte naše stránky, jaký obsah Vás zajímá či pro jaké účely, jste nám dali svůj souhlas. My a naši partneři máme k těmto osobním údajům ve Vašem zařízení přístup nebo je ukládáme a následně zpracováváme pro účely uvedené níže. Tyto údaje nám <b>pomáhají</b> provozovat a zlepšovat naše služby či připravit obsah, který Vás bude zajímat. Pro některé účely je vyžadován <b>Váš souhlas</b>, jindy postačí tzv. oprávněný zájem. Své volby pro volitelné účely, včetně nastavení souhlasu či vznesení námitky vůči oprávněnému zájmu, můžete provést v Podrobném nastavení“ nebo je následně změnit či souhlas odvolat <a href="https://www.cncenter.cz/cookies" target="_blank">zde</a>.<br>Vaše nastavení se použije pro všechny naše webové stránky. Pokud se rozhodnete souhlas neudělit i pro vyžadované reklamní účely pro používání stránek ZDARMA, nabízíme Vám předplatné bez reklam.',
    subBody: 'Chcete používat tento web bez sdílení informací s třetími stranami o historii Vašeho prohlížení a bez cílené reklamy a reklamních bannerů? Stačí se zaregistrovat nebo se přihlásit s Vašim stávajícím účtem a zakoupit předplatné <b>webu bez reklam za ... Kč/měsíc</b>. Informace o zpracování údajů v rámci webu bez reklam naleznete <a href="https://www.cncenter.cz/cookies" target="_blank">zde</a>.<br> Pokud jste již předplatitelem služby Premium či Premium+, získáváte předplatné webu bez reklam jako benefit. Stačí se přihlásit s Vaším Premium účtem a odsouhlasit podmínky webu bez reklam.<br>* Netýká se reklamy zobrazované v rámci obsahu, jako jsou komerční sdělení, native či PR články.',
    butConsent: 'Souhlasím',
    linkPurposes: 'Podrobné nastavení',
    butRegister: 'Koupit',
    linkLogin: 'Přihlásit se',
    purposeListHeadline: `My a našich <a href="javascript:Didomi.preferences.show('vendors')">${partnerNum} partnerů</a> zpracováváme údaje následujícím způsobem:<br>`, // This is followed by dynamic (dash separated) list of purposes from Didomi
    purposeListFallback: `Personalizovaná reklama a obsah, měření reklamy a obsahu, poznatky o okruzích publika a vývoj produktů, Technický provoz stránek, Ukládání a/nebo přístup k informacím v zařízení, Zpracování údajů vydavateli a dalšími partnery` // Text to show when purposeList is false and purposeListHeadline empty
  }
}
