
function cpexLog () { if (window.location.href.indexOf('debug') > -1) { console.log.apply(this, arguments) } }


function addConsentPixel (url) {
  const el = document.createElement('img')
  el.setAttribute('src', url)
  el.style.width = '1px'
  el.style.height = '1px'
  el.style.position = 'absolute'
  el.style.bottom = '0px'
  el.style.right = '0px'
  el.style.border = 'none'
  document.body.appendChild(el)
}

function addDidomi () {
  window.gdprAppliesGlobally = true
  function a (e) {
    if (!window.frames[e]) {
      if (document.body && document.body.firstChild) { var t = document.body; var n = document.createElement('iframe'); n.style.display = 'none'; n.name = e; n.title = e; t.insertBefore(n, t.firstChild) }
      else { setTimeout(function () { a(e) }, 5) }
    }
  }
  function e (n, r, o, c, s) {
    function e (e, t, n, a) { if (typeof n !== 'function') { return } if (!window[r]) { window[r] = [] } var i = false
    if (s) { i = s(e, t, n) } if (!i) { window[r].push({ command: e, parameter: t, callback: n, version: a }) } }
    e.stub = true
    function t (a) {
      if (!window[n] || window[n].stub !== true) { return }
      if (!a.data) { return }
      var i = typeof a.data === 'string'
      var e
      try { e = i ? JSON.parse(a.data) : a.data } catch (t) { return }
      if (e[o]) { var r = e[o]; window[n](r.command, r.parameter, function (e, t) { var n = {}; n[c] = { returnValue: e, success: t, callId: r.callId }; a.source.postMessage(i ? JSON.stringify(n) : n, '*') }, r.version) }
    }
    if (typeof window[n] !== 'function') { window[n] = e; if (window.addEventListener) { window.addEventListener('message', t, false) } else { window.attachEvent('onmessage', t) } }
  }
  e('__tcfapi', '__tcfapiBuffer', '__tcfapiCall', '__tcfapiReturn'); a('__tcfapiLocator');

  function addCustomTemplate () {
    return window.cpexCmpTemplate ? '?target_type=notice&target=' + window.cpexCmpTemplate : '?target=' + document.location.hostname
  }

  (function (didomiKey, template) {
    var t = document.createElement('script')
    t.id = 'spcloader'
    t.type = 'text/javascript'
    t.async = true
    t.src = 'https://sdk.privacy-center.org/' + didomiKey + '/loader.js' + template
    var n = document.getElementsByTagName('script')[0]
    n.parentNode.insertBefore(t, n)
  })('9a8e2159-3781-4da1-9590-fbf86806f86e', addCustomTemplate())
}

export class cpexCMP {
  constructor (config) {
    this.pixelList = config?.pixels || []
    window.didomiEventListeners = window.didomiEventListeners || []
    window.didomiOnReady = window.didomiOnReady || []
    addDidomi()
    this.addConsentPixels()
  }

  addConsentPixels (ignoreTimer) {
    cpexLog('CMP: trying to fire consent pixels')
    // if 24 hours passed, fire consent pixels
    var pixelsLastFired = localStorage.getItem('pixelsLastFired') || 0
    var intervalMs = 24 * 60 * 60 * 1000
    if (Date.now() - intervalMs > pixelsLastFired || ignoreTimer) {
      __tcfapi('addEventListener', 2, (data, success) => {
        if (success) {
          if (data.vendor.consents) {
            if (Object.keys(data.vendor.consents).length > 0) {
              this.pixelList.forEach((url) => {
                addConsentPixel(url.replace('%CONSENT%', data.tcString))
              })
              localStorage.setItem('pixelsLastFired', Date.now())
              cpexLog('CMP: added consent pixels')
            }
          }
        }    
      })
    } else {
      cpexLog('CMP: 24 hours have not yet passed, based on pixelsLastFired in local storage')
    }
  }
}
